import { useInView } from "react-intersection-observer";
import { Flex, Image } from "@mantine/core";
import { useEffect, useState } from "react";

function ImageObserved(model: { dark?: boolean, width: string, height: string, src: string }) {

  const [viwed, setViewed] = useState(false)

  const { ref, inView } = useInView({
    initialInView: true,
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (viwed) {
      return
    }
    if (inView) {
      setViewed(true)
    }
  }, [inView, viwed])


  return (
    <Flex ref={ref}
      p={model.dark ? "xs" : undefined}
      bg={model.dark ? "#000000" : undefined}
      align={'center'}
      justify={'center'}
      style={{
        borderRadius: model.dark ? '4px' : undefined,
      }}
      w="100%"
      // w="max-content"
      >
      <Image
        width={model.width}
        height={model.height}
        bg="#000000"
        styles={{

          image: {
            // borderRadius: '50%',
          }
        }}
        src={inView || viwed ? model.src : ""}
        alt="With default placeholder"
        withPlaceholder
        fit="contain"
      />
    </Flex>
  )
}

export default ImageObserved
import React, { useState } from "react";
import { IconChevronRight } from "@tabler/icons-react";
import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  Box,
  useMantineTheme,
  rem,
  Menu,
  Flex,
} from "@mantine/core";
import { useStore } from "../../hooks/use-store";
import { observer } from "mobx-react";
import EditAccount from "../../components/sidebar/editAccount/EditAccount";

const User = (model: {buyCredits: () => void, billingHistory: () => void}) => {
  const {
    uiStore: { authStore },
  } = useStore();

  const theme = useMantineTheme();

  const [editAccountDrawerOpen, setEditAccountDrawerOpen] = useState(false);

  return (
    <Box
      sx={{
        background: '#000000',
        borderRadius: theme.radius.sm,
      }}
    >
      <UnstyledButton
        sx={{
          display: "block",
          width: "100%",
          // minWidth: '300px',
          height: "100%",
          paddingLeft: theme.spacing.xs,
          paddingRight: theme.spacing.xs,
          paddingBottom: '0.3rem',
          paddingTop: '0.3rem',
          // paddingRight: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color:
            theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

          "&:hover": {
            backgroundColor: 'rgb(88, 169, 121, 0.1)'
          },
        }}
      >
        <Menu
          width={190}
        >
          <Menu.Target>
            <Group>
              <Avatar src={authStore.currentUser?.avatar} radius="xl" size={40} />
              <Flex direction={'column'}>
                <Text color="dimmed" size={10}>
                  {authStore.currentUser?.email}
                </Text>
                <Flex align={'center'} gap={4}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.9723 1.83325C5.91233 1.83325 1.80566 5.93992 1.80566 10.9999C1.80566 16.0599 5.91233 20.1666 10.9723 20.1666C16.0323 20.1666 20.139 16.0599 20.139 10.9999C20.139 5.93992 16.0415 1.83325 10.9723 1.83325ZM14.4098 11.3208L10.9998 15.1983L10.5965 15.6566C10.0373 16.2891 9.579 16.1241 9.579 15.2716V11.6416H8.02066C7.31483 11.6416 7.12233 11.2108 7.58983 10.6791L10.9998 6.80159L11.4032 6.34325C11.9623 5.71075 12.4207 5.87575 12.4207 6.72825V10.3583H13.979C14.6848 10.3583 14.8773 10.7891 14.4098 11.3208Z" fill="#84cc15" />
                  </svg>

                  <Text size={16} weight={700} color="white">
                    {authStore.currentUser?.credits}
                  </Text>
                </Flex>
              </Flex>
              <IconChevronRight size={rem(18)} />
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Settings</Menu.Label>
            <Menu.Item
              icon={null}
              onClick={() => {
                setEditAccountDrawerOpen(true);
              }}
            >
              Account settings
            </Menu.Item>
            <Menu.Item
              icon={null}
              onClick={() => {
                model.buyCredits();
              }}
            >
              Buy credits
            </Menu.Item>       
             <Menu.Item
              icon={null}
              onClick={() => {
                model.billingHistory();
              }}
            >
              Billing history
            </Menu.Item>
            <Menu.Item
              icon={null}
              onClick={() => {
                authStore.logout();
                window.location.href = "/login";
              }}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </UnstyledButton>

      <EditAccount
        visible={editAccountDrawerOpen}
        onClose={() => setEditAccountDrawerOpen(false)}
      />
    </Box>
  );
};

export default observer(User);

import React, { useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "./hooks/use-store";
import LoggedIn from "./pages/routers/logged-in";
import LoggedOut from "./pages/routers/logged-out";
import { AuthState } from "./stores/ui/auth-store/auth-store";
// import Header from "./components/header/header";

import "./App.scss";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Switch } from "react-router-dom";
// import Invite from "./features/invite";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { PostHogProvider } from "posthog-js/react";
import { ModalsProvider } from "@mantine/modals";

function App() {
  const rootStore = useStore();
  const {
    uiStore: { authStore },
  } = rootStore;

  const [colorScheme, setColorScheme] = useState<ColorScheme>("dark");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          other: {
            // background: '#000000'
          },
          colorScheme,
          fontFamily: "Mulish",
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <ModalsProvider>
          <PostHogProvider
            apiKey={'phc_WaKf3NXZeQxEo4RsgTE5t7DwoiZPliPT89VBrsFhP6P'}
            options={{
              session_recording: {
                // WARNING: Only enable this if you understand the security implications
                recordCrossOriginIframes: true,
              },
              api_host: 'https://eu.posthog.com',
            }}
          >
            <Router>
              <Switch>
    
                {authStore.authState === AuthState.LoggedIn ? (
                  <LoggedIn />
                ) : (
                  <LoggedOut />
                )}


              </Switch>
            </Router>
          </PostHogProvider>
        </ModalsProvider>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={"dark"}
        />
      </MantineProvider >
    </ColorSchemeProvider >
  );
}

export default observer(App);


import {
  Paper,
  Container,
  Flex,
  Title,
  PinInput,
} from "@mantine/core";
import { useStore } from "../../../hooks/use-store";
import styles from "./register.module.scss";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
import Logo from "../../../components/Logo";
import { useState } from "react";

interface FormProps {
  code: string;
}

const OtpEnterCodeScreen = (props: { id: string, email: string | null }) => {
  const {
    uiStore: { authStore },
  } = useStore();

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const register = async (data: FormProps) => {
    setLoading(true)
    const res = await authStore.verify({
      id: props.id,
      code: data.code
    });
    console.log(res)
    setLoading(false)
    if (res.ok) {
      // @ts-ignore
      window.location = `/new`;
    } else {
      NOTIFICATION_SERVICE.errorNotification('Wrong code');
    }
  };
  return (
    <div className={styles.container}>
      <Container size={420}>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ background: '#000000' }}>
          <Container size={500} mb={20}>
            <Flex justify={'center'} >
              <Logo size={40} />
            </Flex>
            <Flex justify={'center'} mt={20} mb={10} >
              <Title
                color="white"
                align="center"
                size={24}
              >

                {t("check.inbox")}
              </Title>
            </Flex>
            <Flex justify={'center'} mb={10}>
              <Title
                color="rgba(190, 190, 190, 1)"
                align="center"
                size={14}
              >
                {t("sent.email.to")}
                &nbsp;
                {props.email}
                &nbsp;
                {t("with.code")}
              </Title>
            </Flex>
          </Container>
          <Flex justify={'center'}>

            <PinInput
              autoFocus
              size="xl"
              disabled={loading}
              onComplete={async (value: string) => {
                await register({ code: value });
              }}
              required
              type={'number'}
            />
          </Flex>
        </Paper>
      </Container>
    </div>
  );
};

export default OtpEnterCodeScreen;

import { action, computed, makeObservable, observable } from "mobx";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import User from "../../data/models/user";
import { StorageItems } from "../../../services/core/storage-service";

export enum AuthState {
  Authenticating = "authenticating",
  LoggedIn = "loggedIn",
  LoggedOut = "loggedOut",
}

class AuthStore {
  @observable
  currentUser: User | null;
  @observable
  authState: AuthState = AuthState.LoggedOut;

  constructor() {
    makeObservable(this);
  }

  async loginIfTokenExists() {
    const token = getEnv().apiFactory.authService.getCredentials(
      StorageItems.TOKEN
    );

    if (token) {
      await this.loginWithToken();
    }
  }

  @action
  logout = () => {
    const { apiFactory } = getEnv();

    apiFactory.authService.clearCredentials();

    this.setUser(null);
    this.setAuthState(AuthState.LoggedOut);
    apiFactory.saveToken("");
  };

  @computed
  get user() {
    return this.currentUser;
  }

  @computed
  get isLoggedIn() {
    return this.authState === AuthState.LoggedIn;
  }

  @action
  setAuthState(authState: AuthState) {
    this.authState = authState;
  }

  @action
  setUser(model: { user: any, intercomHash: string } | null) {
    if (!model) {
      this.currentUser = null;
    } else {
      this.currentUser = new User(model.user);
      console.log(this.currentUser)
      if (window?.Intercom) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "paz8i86k",
          firstName: this.currentUser.firstName, // Full name
          lastName: this.currentUser.lastName,
          email: this.currentUser.email,
          user_hash: model.intercomHash,
        });
      }
      if (window?.EncTracking) {
        window.EncTracking.identify({ 
          email: this.currentUser.email,
          userId: this.currentUser.id
        });
      }
    }
  }

  private async afterLoginEvents(token: string, user: any, intercomHash: any) {
    const { apiFactory } = getEnv();

    if (token) {
      apiFactory.saveToken(token);
      apiFactory.authService.storeCredentials(token);
    }

    this.setAuthState(AuthState.LoggedIn);
    this.setUser({ user, intercomHash });
  }

  loginWithToken = async () => {
    try {
      this.setAuthState(AuthState.Authenticating);
      const result = await getEnv().apiFactory.authService.getProfile();
      await this.afterLoginEvents(result.token, result.user, result.intercomHash);
    } catch (e) {
      this.setAuthState(AuthState.LoggedOut);
      console.log(e, "Failed to login from token");
    }
  };

  @action
  syncUser = async () => {
    try {
      const result = await getEnv().apiFactory.authService.getProfile();
      await this.afterLoginEvents(result.token, result.user, result.intercomHash);
    } catch (e) {
      this.setAuthState(AuthState.LoggedOut);
      console.log(e, "Failed to login from token");
    }
  };

  login = async (email: string, password: string): Promise<any> => {
    try {
      this.setAuthState(AuthState.Authenticating);

      const result = await getEnv().apiFactory.authService.login(
        email,
        password
      );
      await this.afterLoginEvents(result.token, result.user, result.intercomHash);
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      this.setAuthState(AuthState.LoggedOut);
      console.log(e, "Failed to login");
    }
  };

  update = async (model: {
    avatar: string | null;
    firstName: string;
    lastName: string;
  }): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.update(
        model.avatar,
        model.firstName,
        model.lastName
      );

      this.setUser({
        user: {
          ...this.currentUser,
          avatar: model.avatar,
          first_name: model.firstName,
          last_name: model.lastName,
        },
        intercomHash: "",
      }
      );
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      console.log(e, "Failed to login");
    }
  };

  register = async (model: {
    firstName: string;
    lastName: string;
    password: string;
    email?: string;
    avatar?: string;
    inviteCode?: string;
  }): Promise<{ ok: boolean, siteId: string }> => {
    try {
      this.setAuthState(AuthState.Authenticating);
      const result = await getEnv().apiFactory.authService.register(model);
      await this.afterLoginEvents(result.token, result.user, result.intercomHash);
      // // @ts-ignore
      // window.fbq && window.fbq("track", "CompleteRegistration");
      // @ts-ignore
      window.lintrk && window.lintrk("track", { conversion_id: 9254930 });
      return {
        siteId: result.siteId,
        ok: true,
      };
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      this.setAuthState(AuthState.LoggedOut);
      return {
        ok: false,
        siteId: ''
      };
    }
  };

  verify = async (model: {
    id: string;
    code: string;
  }): Promise<{ ok: boolean }> => {
    try {
      this.setAuthState(AuthState.Authenticating);
      const result = await getEnv().apiFactory.authService.authByCode(model);
      if (result.token) {
        await this.afterLoginEvents(result.token, result.user, result.intercomHash);
        return {
          ok: true,
        };
      } else {
        return {
          ok: false,
        };
      }
      // // @ts-ignore
      // window.fbq && window.fbq("track", "CompleteRegistration");
      // @ts-ignore
      // window.lintrk && window.lintrk("track", { conversion_id: 9254930 });
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      this.setAuthState(AuthState.LoggedOut);
      return {
        ok: false,
      };
    }
  };

  attachEmail = async (email: string): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.attachEmail(email);
      return {
        ok: true,
      };
    } catch (e) {
      return {
        ok: false,
      };
      // this.setAuthState(AuthState.LoggedOut);
      // console.log(e, "Failed to register");
    }
  };

  forgotPassword = async (email: string): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.forgotPassword(email);
      return {
        ok: true,
      };
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      return {
        ok: false,
      };
    }
  };

  resetPassword = async (password: string, hash: string): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.resetPassword(password, hash);
      return {
        ok: true,
      };
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      return {
        ok: false,
      };
    }
  };
}

export default AuthStore;
